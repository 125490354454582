// src/pages/ProjectDetails.jsx
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../utils/firebase'
import { collection, getDocs } from 'firebase/firestore'
import Navbar from '../components/Navbar'
import HeroProject from '../components/projectsComponents/Hero'
import { useMediaQuery } from 'react-responsive'
import CustomCursor from '../components/customCursor'
import Slider1 from '../components/projectsComponents/slider1'
import Slider2 from '../components/projectsComponents/slider2'
import Slider3 from '../components/projectsComponents/slider3'
import ImageComponent from '../components/projectsComponents/ImageComponent'
import TextComponent from '../components/projectsComponents/TextComponent'

let completedProjects = {}
let ongoingProjects = {}
let upcomingProjects = {}
let project = null

let projectName = ''
let projectType = ''
let overview = ''
let projectDetails = []
let location = ''
let pictures = []
let text1 = []
let text2 = []

const ProjectDetails = () => {
  const { projectUrl } = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [isLoading, setIsLoading] = useState(false)

  const fetchProject = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'projects'))
      const projectsList = querySnapshot.docs.map(doc => doc.data())
      // console.log(projectsList)
      completedProjects = projectsList[0]
      ongoingProjects = projectsList[1]
      upcomingProjects = projectsList[2]

      const allProjects = projectsList.flatMap(projectGroup =>
        Object.values(projectGroup)
      )
      //   console.log('All projects:', allProjects) // Debugging log

      const foundProject = allProjects.find(p => p.url === projectUrl)
      //   console.log('Found project:', foundProject) // Debugging log

      project = foundProject

      //   console.log('Project:', project) // Debugging log
      await setVariables()
      console.log(projectName)
      console.log(projectType)
      console.log(overview)
      console.log(projectDetails)
      console.log(location)
      console.log(pictures)
    } catch (error) {
      console.error('Error fetching projects:', error)
    }
  }

  const setVariables = async () => {
    projectName = project.name
    projectType = project.type
    overview = project['project-overview']
    projectDetails = project['project-details']
    location = project.location
    pictures = project.pictures
    text1 = project['text-1']
    text2 = project['text-2']
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchProject()
      setIsLoading(false)
    }
    fetchData()
  }, [])

  if (!project) {
    return <p className='w-full h-screen flex justify-center items-center'>404 - Project Not Found</p>
  }

  return isLoading ? (
    <div className='flex justify-center items-center h-screen'>
      <p className='text-2xl'>Loading...</p>
    </div>
  ) : (
    <div className='bg-[#E6E9F4]'>
      {!isMobile && <CustomCursor />}
      <Navbar bgColor='black' headerColor='[#E6E9F4]' />
      <HeroProject
        projectName={projectName || 'Not Available'}
        bgLetter={projectName ? projectName.charAt(0) : 'N/A'}
        projectType={projectType || 'Not Available'}
        overview={overview || 'Not Available'}
        projectDetails={Array.isArray(projectDetails) && projectDetails.length > 0 ? projectDetails : ['Not Available']}
        location={location || 'Not Available'}
      />
      <div className='pb-20'>
        {Array.isArray(pictures) && pictures.length > 0 ? (
          pictures.map((picture, index) => (
            <div key={index}>
              {Array.isArray(picture.images) ? (
                typeof picture.images[0] === 'string' ? (
                  <>
                    {index === 0 ? (
                      <>
                        <Slider2 images={picture.images} text={picture.name} />
                        <TextComponent heading={text1[0]} desc={text1[1]} />
                      </>
                    ) : (
                      <Slider1 images={picture.images} text={picture.name} />
                    )}
                  </>
                ) : (
                  <Slider3 images={picture.images} text={picture.name} />
                )
              ) : (
                <div>
                  <ImageComponent
                    name={picture.name}
                    margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'
                    src={picture.images}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No pictures are available</p>
        )}
        {text2 && text2.length > 0 && (
          <TextComponent heading={text2[0]} desc={text2[1]} />
        )}
      </div>
    </div>
  )
}

export default ProjectDetails
