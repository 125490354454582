import React, { useRef } from 'react';
import Navbar from '../Navbar';
import { useMediaQuery } from 'react-responsive';
import emailjs from '@emailjs/browser';
import { Toaster, toast } from 'react-hot-toast';

import "./styles.scss";
import CustomCursor from '../customCursor';

const Raiseaquote = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const btn = e.target.querySelector('button[type="submit"]');
        btn.textContent = 'Sending...';

        const serviceID = 'service_utjz739';
        const templateID = 'template_cno1b2c';
        const publicKey = '6WS5UHi08Ruq4D3oY';

        emailjs.sendForm(serviceID, templateID, form.current, publicKey)
            .then(() => {
                btn.textContent = 'Submit';
                toast.success('Message received!', {
                    style: {
                        background: 'black',
                        color: 'white'
                    }
                });
                form.current.reset(); // Clear the form
            }, (err) => {
                btn.value = 'Submit';
                toast.error('Failed to send message.', {
                    style: {
                        background: 'black',
                        color: 'white'
                    }
                });
                console.error(JSON.stringify(err));
            });
    };

    return (
        <div className='bg-white'>
            <Toaster />
            {!isMobile && (
                <CustomCursor />
            )}
            <Navbar bgColor="lightBeige" headerColor="white" />
            <div className='px-5 sm:px-16 md:px-24 xl:px-32 pt-28 md:pt-32'>
                <div className='flex items-center border-b border-[#b6b6b6] pb-5'>
                    <div className='font-interMedium flex flex-col gap-1'>
                        <p className='text-[1.8rem] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[3rem]'>Raise a Quote</p>
                        <p className='font-interRegular text-[0.9rem] md:text-[1rem] lg:text-[1.15rem] xl:text-[1.25rem] w-11/12 md:w-9/12 xl:w-9/12 2xl:w-7/12'>Get started by providing some details and we'll connect you with qualified agents who can offer personalized quotes</p>
                    </div>
                    <div className='w-5/12 md:w-5/12 lg:w-4/12 xl:w-3/12 2xl:w-1/6'>
                        <p className='whitespace-nowrap font-montserratMedium text-[2.5rem] md:text-[4rem] lg:text-[6rem] xl:text-[7.5rem]'>“ : )</p>
                    </div>
                </div>

                {/* form */}
                <div className='font-interMedium pt-10 text-[1.25rem] md:text-[1.35rem] lg:text-[1.5rem]'>
                    <div className='text-[1.8rem] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[3rem] pb-8 leading-tight'> 
                        <p>hello, </p>
                        <p>Concicon!</p>
                    </div>

                    <form ref={form} onSubmit={sendEmail} className=''>
                        <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                            <label htmlFor="name" className='whitespace-nowrap pr-5'>My Name is</label>
                            <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" name='user_name' placeholder='Your Name' required />
                            <label htmlFor="address" className='whitespace-nowrap pr-5'>, I am from</label>
                            <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" name='user_address' placeholder='Your Address' required />
                        </div>

                        <label htmlFor="" className='block pr-5 py-6'>I would like to discuss about</label>
                        <div className='flex items-center pb-0 md:pb-2'>
                            <input type="checkbox" id="apartments" name="apartments" className='' />
                            <label htmlFor="apartments" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Apartments</label>
                        </div>

                        <div className='flex items-center pb-0 md:pb-2'>
                            <input type="checkbox" id="rentals-residential" name="rentals_residential" />
                            <label htmlFor="rentals-residential" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Rentals (Residential)</label>
                        </div>

                        <div className='flex items-center'>
                            <input type="checkbox" id="rentals-commercial" name="rentals_commercial" />
                            <label htmlFor="rentals-commercial" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Rentals (Commercial)</label>
                        </div>

                        <label htmlFor="questions" className='block pr-5 pt-8 pb-4'>I would like to write</label>
                        <textarea id="questions" name="questions" rows="4" className='w-full md:w-9/12 inline border-b border-black text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' placeholder='Questions you want to ask' required>
                        </textarea>

                        <div className='w-full md:w-9/12'>
                            <div className='flex flex-col md:flex-row gap-3 pt-10'>
                                <label htmlFor="preffered-location" className='whitespace-nowrap pr-5'>My Preferred location is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" name='preferred_location' placeholder='Enter Location' required />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="budget" className='whitespace-nowrap pr-5'>My Budget is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" name='budget' placeholder='Enter Budget in rupees' required />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="email" className='whitespace-nowrap pr-5'>My Email is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="email" name='user_email' placeholder='Enter Email' required />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="phone" className='whitespace-nowrap pr-5'>You can reach me on</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" name='phone' placeholder='Your Phone Number' required />
                            </div>
                        </div>

                        <div className="flex justify-center py-16">
                            <button
                                type="submit"
                                className="bg-black hover:bg-[#cfcfcf] transition-colors duration-200 hover:text-black text-white text-base md:text-lg py-3 px-10 rounded-full flex items-center font-interMedium"
                            >
                                Submit
                                <svg
                                    className="w-3 h-3 md:w-3.5 md:h-3.5 ml-2"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 5h12m0 0L9 1m4 4L9 9"
                                    />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Raiseaquote;