import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-creative';
import TextComponent2 from '../../components/projectsComponents/TextComponent2';

const Slider3 = ({images, text}) => {
    return (
        <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>{text}</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        {images.map((imageData, index) => (
                            <SwiperSlide key={index}>
                                <img src={imageData.image} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' />
                                <TextComponent2 heading={imageData.info[0]} desc={imageData.info[1]} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
    );
};

export default Slider3;
