import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-creative';

const Slider1 = ({images, text}) => {
    return (
        <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
            <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>{text}</p>
            </div>
            <div className=''>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                    pagination={{ clickable: true }}
                    spaceBetween={25}
                    slidesPerView={1}
                    navigation
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    effect='creative'
                    creativeEffect={{
                        prev: {
                            shadow: true,
                            translate: ['-121%', 0, -500],
                        },
                        next: {
                            shadow: true,
                            translate: ['121%', 0, -500],
                        },
                    }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Slider1;
